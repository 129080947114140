<template>
  <div>
    <div class="modal ">
      <div class="separator d-flex">
        <hr v-if="['center','right'].includes(type)" class="line " />
        <p class="mb-0 " :style="`font-weight:${weight};font-size:${size};width:${width}; text-align: ${align};`">
            {{ label }}
        </p>
        <hr  v-if="['center','left'].includes(type)" class="line" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:{
        size:{
            type:String,
            default:"11px"
        },
        weight:{
            type:String,
            default:"600"
        },
        label:{
            type:String,
            default:"Separator texte"
        },
        width:{
            type:String,
            default:"50%"
        },
        align:{
            type:String,
            default:"center"
        },
        type:{
            type:String,
            default:"center"
        },
        
    }
};
</script>

<style>

.separator {
  /* width: calc(100% - 20px); */
  /* display: grid; */
  /* grid-template-columns: 1fr 2fr 1fr; */
  /* gap: 10px; */
  color: #8B8E98;
  margin: 0 10px;
}

.separator > p {
  word-break: keep-all;
  display: block ;
  /* text-align: center; */
  /* font-weight: 600; */
  /* font-size: 11px; */
  /* margin: auto; */
  /* min-width: 30%; */

}

.separator .line {
  display: inline-block;
  width: 100%;
  height: 1px;
  border: 0;
  background-color: #e8e8e8;
  margin: auto;
}


</style>
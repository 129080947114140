<template>
    <div class="mb-5">
        <div class="select-menu " :class="selectMenuClass" @click="toggleActive()">
            <div v-if="privateModel" class="mb-1">
               <span class="label"> {{ privateModel ?label : ""}}</span>
            </div>
            <div class="select-btn">
                
                <span class="sBtn-text">{{ privateModel ? privateModel.name : label }} </span>

               <span>
               <v-btn v-if="privateModel" icon> <v-icon  @click="()=>privateModel=null" >mdi-close</v-icon></v-btn>
                <v-icon>mdi-chevron-down</v-icon>
               </span>
            </div>
            <div v-if="error" class="error-class ">
               <span> {{ error }}</span>
            </div>
            <ul class="options ">
                <li v-for="item, i of items" :key="i" @click="selectItem(item)" class="option ">
                    <v-icon :color="getItemColor(item)">{{ getItemIcon(item) }}</v-icon>
                    <span class="option-text">{{ item.name }}</span>
                    <span class="badge-text ml-2 rounded-pill px-2 white--text" v-if="item.badge"
                        :class="getItemColor(item)">{{ item.badge }}</span>
                </li>

            </ul>
        </div>

        <v-dialog max-width="40rem" v-model="showAlert">
            <v-alert class="white my-0 py-5" text dense color="read" icon="mdi-alert" border="left">
                <p class="">{{ unauthorizeMessage }}</p>
                <v-divider class="my-4 read" style="opacity: 0.22"></v-divider>

                <div>
                    <div>
                        <p v-html="getUnthorizeAlertDetailMessage(authorizePlans, userPlan)" class="mb-0">

                        </p>
                    </div>
                </div>
                <div class="d-flex justify-end mt-5">
                    <v-btn color="green" outlined @click="() => { showAlert = false, authorizePlans = [] }">{{ "OK"
                        }}</v-btn>

                </div>
            </v-alert>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => []
        },
        rules: {
            type: Array,
            default: () => []
        },
        model: null,
        userPlan: {
            type: String,
            required: false
        },
        label: {
            type: String,
            default: 'Select your option'
        },
        unauthorizeMessage: {
            type: String,
            default: "You are not authorized to select this option."
        },
    },
    data() {
        return {
            selectMenuClass: "",
            showAlert: false,
            authorizePlans: [],
            privateModel: null,
            error:"",
            privateValueIsProvideOnce:false
        }
    },
    watch: {
        async privateModel() {
            if(this.privateValueIsProvideOnce){
                this.validate()
            }
            if (this.privateModel && this.privateModel.name) {
                this.privateValueIsProvideOnce =true 
                await this.$emit("update:model", this.privateModel.name);
                this.model = this.privateModel.name
            }
        },
        async items() {
            if (Array.isArray(this.items)) {
                let match = false
                for (const item of this.items) {
                    if (item.name == this.model) {
                        this.privateModel = item
                        match = true
                        break
                    }
                }

                if (!match) {
                    this.privateModel = null
                    await this.$emit("update:model", undefined);
                }

            } else {
                // this.model = undefined
                await this.$emit("update:model", undefined);

            }
        }
    },
    methods: {
        toggleActive() {
            if (this.selectMenuClass)
                this.selectMenuClass = ""
            else this.selectMenuClass = "active"
        },
        selectItem(item) {

            if (this.itemIsAuthorized(item)) {
                this.privateModel = item
            } else {
                this.authorizePlans = item.authorizePlans
                this.showAlert = true
            }
        },
        getItemIcon(item) {
            let icon = "mdi-bracket"

            if (this.itemIsAuthorized(item)) {
                if (item.icon) {
                    icon = item.icon
                }
            } else {
                icon = 'mdi-lock'
            }

            return icon

        },
        getItemColor(item) {
            let color = "blue"

            if (this.itemIsAuthorized(item)) {
                if (item.color) {
                    color = item.color
                }
            } else {
                color = 'grey'
            }

            return color

        },
        itemIsAuthorized(item) {
            let isAuthorize = true
            if (Array.isArray(item.authorizePlans)) {
                isAuthorize = false
                if (this.userPlan && item.authorizePlans.includes(this.userPlan)) {

                    isAuthorize = true
                }
            }

            return isAuthorize
        },
        getUnthorizeAlertDetailMessage(allowedPlans, userPlan) {
            const allowedPlansList = allowedPlans.join(', ');
            return `Only users with a <strong> ${allowedPlansList} </strong> plan can use this option. You have a <strong> ${userPlan} </strong> plan.`;

        },
        getModelContent(name) {
            if (Array.isArray(this.items)) {
                for (let item of this.items) {
                    if (item.name == name) return item
                }
            }
        },
        validate() {
            this.error = '';
            for (let rule of this.rules) {
                const result = rule(this.privateModel ? this.privateModel.name : undefined);
                if (result !== true) {
                    this.error = result;
                    break;
                }
            }

            return this.error ? false : true
        }
    },
    mounted() {
        this.privateModel = this.getModelContent(this.model)
    }
};
</script>

<style>
/* ===== Google Font Import - Poppins ===== */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap'); */
/* *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}
body{
    background: #E3F2FD;
} */
.select-menu {
    width: 90%;
    /* margin: 140px auto; */
}
.label{
    font-size: 13px;
    color: rgb(99, 97, 97);
    transition: 0.3s;
    font-weight: bold;
}
.error-class{
    margin-top: 2px;
    font-size: 12px;
    color: rgb(249, 0, 0);
    transition: 0.3s;
    /* font-weight: bold; */
}

.select-menu .select-btn {
    display: flex;
    height: 55px;
    background: #fff;
    padding: 20px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.select-btn i {
    font-size: 25px;
    transition: 0.3s;
}

.select-menu.active .select-btn i {
    transform: rotate(-180deg);
}

.select-menu .options {
    position: relative;
    padding: 20px;
    margin-top: 10px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
    display: none;
}

.select-menu.active .options {
    display: block;
}

.options .option {
    display: flex;
    height: 55px;
    cursor: pointer;
    padding: 0 16px;
    border-radius: 8px;
    align-items: center;
    background: #fff;
}

.options .option:hover {
    background: #F2F2F2;
}

.option i {
    font-size: 25px;
    margin-right: 12px;
}

.option .option-text {
    font-size: 18px;
    color: #333;
}

.option .badge-text {
    font-size: 12px;
    font-weight: bold;
}
</style>
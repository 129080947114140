<template>
  <v-card class="px-md-5 py-5 scrollbar-style-14" :class="editorClass" color="white">
    <div class="container scrollbar-style-14">
      <!-- <v-card-title>{{ title }}</v-card-title> -->
      <div class="h3-title mb-5">{{ title }}</div>
      <!-- {{ fields[3]}} -->
      <!-- {{ models }} -->


      <v-form ref="form" class=" " lazy-validation>
        <div class="editor-form scrollbar-style-14 ">
          <v-row class="py-4 multiple-container " v-if="multiple && !id">
            <v-col v-if="models.length == 0" cols="12">
              <div class="d-flex justify-center py-6 align-items-center">
                <div class="text-center">
                  <v-icon>mdi-thought-bubble</v-icon>
                  <p class="mt-3">When you add a row, he will come here.</p>
                </div>
              </div>
            </v-col>
            <v-col v-else v-for="(iModel, e) of models" :key="e" cols="12" class="">
              <v-row class="pb-5 mb-3">

                <v-col v-for="(field, i) of iModel.fields" :key="i" :cols="multiple ? field.multipleCol : field.col"
                  :md="multiple ? field.multipleMdCol : field.md"
                  :v-show="field.type == 'Static' || !isValidField(field) ? false : true">

                  <div v-if="isValidField(field)">
                    <div v-if="field.type == 'Separator'">
                      <separator :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :size="field.size"
                        :weight="field.weight" :width="field.width" :align="field.align" :type="field.textType" />
                    </div>
                    <div v-if="field.type == 'Preview'">
                      <div class="mb-2 d-flex justify-space-between">
                        <h3 class=" h5-title ">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }} :</h3>
                        <button class="elevation-0 btn px-2" @click.prevent="copyToClipboard(iModel.model[field.name])"><v-icon size=".9rem">{{
                          copiedText == 'Copy' ?
                            'mdi-content-copy' : 'mdi-check'}}</v-icon> {{ copiedText
                          }}</button>
                      </div>
                      <pre class="scrollbar-style-14">{{ iModel.model[field.name] }}</pre>
                      <v-divider></v-divider>
                    </div>
                    <div :class="field.class" v-if="field.type == 'HTMLPreview'">
                      <div class="mb-2 d-flex justify-space-between">
                        <div  v-html="field.value " class="html-preview container"></div>
                      </div>
                    </div>
                    <v-text-field clear-icon="mdi-close-circle" clearable v-if="field.type == 'String'"
                      v-model="iModel.model[field.name]" :rules="getFieldRules(field)" color="main" :hint="field.hint"
                      :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :placeholder="field.placeholder"
                      :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'" outlined dense></v-text-field>
                    <v-text-field v-if="field.type == 'Number'" type="number" v-model="iModel.model[field.name]"
                      :rules="getFieldRules(field)" color="main" :hint="field.hint" :placeholder="field.placeholder"
                      dense :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :prepend-inner-icon="field.icon ? field.icon : 'mdi-numeric'
                        " outlined></v-text-field>

                    <div v-if="field.type == 'Select'">
                      <v-select clear-icon="mdi-close-circle" clearable :items="field.select.data"
                        :label="field.label + (fieldIsRequired(field) ? ' *' : '')" v-model="iModel.model[field.name]"
                        :rules="getFieldRules(field)" outlined :hint="field.hint" :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'
                          " small-chips dense :multiple="field.select.multiple"></v-select>

                      <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.select.allowOtherValue == true &&
                        iModel.model[field.name] == 'Autre'
                      " v-model="iModel.model[field.name + 'Other']" :rules="getFieldOtherRules(field)" color="main"
                        :hint="field.hint" :label="'Spécifier votre suggestion'" :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'
                          " outlined />
                    </div>

                    <div v-if="field.type == 'Color'">
                      <v-color-picker v-model="iModel.model[field.name]" class="ma-2" hide-canvas></v-color-picker>
                    </div>
                    <div v-if="field.type == 'Date'">
                      <v-menu :ref="field.name" v-model="dateMenus[field.name]" :close-on-content-click="false"
                        :return-value.sync="iModel.model[field.name]" transition="scale-transition" offset-y
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-combobox v-model="iModel.model[field.name]" :multiple="field.range ? field.range : false"
                            chips small-chips :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                            prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" outlined></v-combobox>
                        </template>
                        <v-date-picker v-model="iModel.model[field.name]" :multiple="field.range ? field.range : false"
                          no-title scrollable>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="dateMenus[field.name] = false">Annuler</v-btn>
                          <v-btn text color="primary" @click="
                            $refs[field.name][0].save(iModel.model[field.name])
                            ">
                            Confirmer
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </div>

                    <div>
                      <v-menu ref="timeMenu" v-if="field.type == 'Time'" v-model="timeMenu"
                        :close-on-content-click="false" :nudge-right="40" :return-value.sync="iModel.model[field.name]"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">

                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field clear-icon="mdi-close-circle" clearable dense v-model="iModel.model[field.name]"
                            :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                            prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                            v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="timeMenu" v-model="iModel.model[field.name]" full-width @click:minute="
                          $refs.timeMenu[0].save(iModel.model[field.name])
                          "></v-time-picker>
                      </v-menu>
                    </div>
                    <div v-if="field.type == 'Radio'">
                      <p>{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                      <v-radio-group row v-model="iModel.model[field.name]">
                        <v-radio v-for="(item, i) of field.radio.data" :key="i" color="primary" :label="item.name"
                          :value="item.value"></v-radio>
                      </v-radio-group>
                    </div>
                    <div v-if="field.type == 'Switch'">
                      <!-- <p class="h4-description">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
    <v-switch v-model="iModel.model[field.name]"></v-switch> -->
                      <div class="editor-switch-holder grey lighten-5">
                        <div class="editor-switch-label">
                          <v-icon v-if="field.icon" size="16px" class="mr-2">{{
                            field.icon
                            }}</v-icon>
                          <span class="font-weight-bolder">{{
                            field.label
                            }}</span>
                        </div>
                        <div class="editor-switch-toggle">
                          <input v-model="iModel.model[field.name]" type="checkbox"
                            :id="'isActorId' + e + `-${field.name}`" />
                          <label :for="'isActorId' + e + `-${field.name}`"></label>
                        </div>
                      </div>
                    </div>
                    <div v-if="field.type == 'File'">
                      <v-file-input clear-icon="mdi-close-circle" clearable v-model="iModel.model[field.name]"
                        :label="field.label + (fieldIsRequired(field) ? ' *' : '')"></v-file-input>
                    </div>

                    <div v-if="field.type == 'Code'">
                      <!-- advanced -->
                      <!-- {{ iModel.model[field.name] }} -->
                      <p class="mb-1">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                      <p class="mb-1 sm-text">{{ field.hint }}</p>
                      <codemirror v-model="iModel.model[field.name]" :code="iModel.model[field.name]"
                        :options="codemirrorOption" class="scrollbar-style-14 h-multiple-codemirror" :lintOptions="{
                          sub: true,
                          notypeof: true,
                        }">
                      </codemirror>
                    </div>
                  </div>
                </v-col>

                <v-col cols="1" class="d-flex justify-center align-center">
                  <v-btn class="red--text" icon @click="removeModel(e)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-col>
              </v-row>
              <div v-if="models.length - 1 > (e)">
                <v-divider class="grey lighten-4"></v-divider>
                <v-divider class="grey lighten-4"></v-divider>
                <v-divider class="grey lighten-4"></v-divider>
                <v-divider class="grey lighten-4"></v-divider>
                <v-divider class="grey lighten-4"></v-divider>
              </div>
            </v-col>

          </v-row>
          <v-row class="single-container" v-else>
            <v-col v-for="(field, i) of fields" :key="i" :cols="field.col" :md="field.md ? field.md : field.col"
              :v-show="(field.type == 'Static' || !isValidField(field)) ? false : true">
              <div v-if="isValidField(field)">

                <div v-if="field.type == 'Separator'">
                  <separator :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :size="field.size"
                    :weight="field.weight" :width="field.width" :align="field.align" :type="field.textType" />
                </div>
                <div v-if="field.type == 'Preview'">

                  <div class="mb-2 d-flex  justify-space-between">
                    <h3 class="mb-1">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }} :</h3>

                    <button class="elevation-0 btn px-2" @click.prevent="copyToClipboard( model[field.name])"><v-icon size=".9rem">{{ copiedText
                      == 'Copy'
                      ?
                        'mdi-content-copy' : 'mdi-check'}}</v-icon> {{ copiedText
                      }}</button>
                  </div>
                  <pre>{{ model[field.name] }}</pre>

                  <v-divider></v-divider>
                </div>
                <div :class="field.class" v-if="field.type == 'HTMLPreview'">
                      <div class="mb-2 d-flex justify-space-between">
                        <div  v-html="field.value " class="html-preview container"></div>
                      </div>
                    </div>
                <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.type == 'String'"
                  v-model="model[field.name]" :rules="getFieldRules(field)" :hint="field.hint"
                  :placeholder="field.placeholder" color="main"
                  :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                  :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'" outlined></v-text-field>

                <v-text-field clear-icon="mdi-close-circle" clearable v-if="field.type == 'Password'"
                  @click:append="variables[field.name] = !variables[field.name]" v-model="model[field.name]"
                  :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :hint="field.hint"
                  :prepend-inner-icon="field.icon ? field.icon : 'mdi-key'" :append-icon="variables[field.name] ? 'mdi-eye' : 'mdi-eye-off'
                    " :rules="getFieldRules(field)" :type="variables[field.name] ? 'password' : 'text'" counter
                  outlined dense></v-text-field>
                <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.type == 'Number'" type="number"
                  :hint="field.hint" :placeholder="field.placeholder" v-model="model[field.name]"
                  :rules="getFieldRules(field)" color="main" :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                  :prepend-inner-icon="field.icon ? field.icon : 'mdi-numeric'" outlined></v-text-field>

                <v-btn dense v-if="field.type == 'Button'" @click="executeButtonAction(field.action)"
                  :color="field.color" class="w-100" :loading="loading">
                  {{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}
                </v-btn>

                <div v-if="field.type == 'Select'">
                  <v-select clear-icon="mdi-close-circle" clearable :items="field.select.data"
                    :label="field.label + (fieldIsRequired(field) ? ' *' : '')" v-model="model[field.name]"
                    :hint="field.hint" :rules="getFieldRules(field)"
                    @change="field.onChange ? field.onChange.function : ''" outlined
                    :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'" chips dense
                    :multiple="field.select.multiple"></v-select>

                  <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.select.allowOtherValue == true &&
                    model[field.name] == 'Autre'
                  " v-model="model[field.name + 'Other']" :rules="getFieldOtherRules(field)" color="main"
                    :label="'Spécifier votre suggestion'" :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'"
                    outlined />
                </div>
                <div v-if="field.type == 'SelectX'">
                  <!-- -->

                  <select-x :items="field.select.data" :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                    v-model="model[field.name]" v-bind:model.sync="model[field.name]" :hint="field.hint"
                    :rules="getFieldRules(field)" @change="field.onChange ? field.onChange.function : ''" outlined
                    :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'" chips dense :userPlan="field.userPlan"
                    :multiple="field.select.multiple" :ref="field.name + 'Refs' + i" />
                </div>
                <div v-if="field.type == 'AutoComplete'">
                  <v-autocomplete clear-icon="mdi-close-circle" clearable
                    :label="field.label + (fieldIsRequired(field) ? ' *' : '')" v-model="model[field.name]"
                    :hint="field.hint" :items="field.select.data" :rules="getFieldRules(field)"
                    @change="field.onChange ? field.onChange.function : ''" outlined
                    :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'" chips dense
                    :multiple="field.select.multiple">
                  </v-autocomplete>
                </div>

                <div v-if="field.type == 'Color'">
                  <v-color-picker v-model="model[field.name]" class="ma-2" hide-canvas></v-color-picker>
                </div>
                <div v-if="field.type == 'Date'">
                  <v-menu :ref="field.name" v-model="dateMenus[field.name]" :close-on-content-click="false"
                    :return-value.sync="model[field.name]" transition="scale-transition" offset-y min-width="auto">

                    <template v-slot:activator="{ on, attrs }">
                      <v-combobox v-model="model[field.name]" :multiple="field.range ? field.range : false" chips
                        small-chips :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" :hint="field.hint" v-on="on"
                        outlined></v-combobox>
                    </template>
                    <v-date-picker v-model="model[field.name]" :multiple="field.range ? field.range : false" no-title
                      scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="dateMenus[field.name] = false">Annuler</v-btn>
                      <v-btn text color="primary" @click="$refs[field.name][0].save(model[field.name])">
                        Confirmer
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div>
                  <v-menu ref="timeMenu" v-if="field.type == 'Time'" v-model="timeMenu" :close-on-content-click="false"
                    :nudge-right="40" :return-value.sync="model[field.name]" transition="scale-transition" offset-y
                    max-width="290px" min-width="290px">

                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field clear-icon="mdi-close-circle" clearable dense v-model="model[field.name]"
                        :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :hint="field.hint"
                        prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="timeMenu" v-model="model[field.name]" full-width
                      @click:minute="$refs.timeMenu[0].save(model[field.name])"></v-time-picker>
                  </v-menu>
                </div>
                <div v-if="field.type == 'Radio'">
                  <p>{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                  <v-radio-group row v-model="model[field.name]">
                    <v-radio v-for="(item, i) of field.radio.data" :key="i" color="primary" :label="item.name"
                      :value="item.value"></v-radio>
                  </v-radio-group>
                </div>
                <div v-if="field.type == 'Phone'" class="mb-5">
                <VuePhoneNumberInput v-if="field.type == 'Phone'" :default-country-code="DEFAULT_COUNTRY_CODE || 'BJ'"
                  v-model="model[field.name]" @update="(event) => {
                      vuePhoneNumberInputChange(event, field);
                    }
                    " show-code-on-list:true :required="field.isRequiredForAdd ? true : false" :translations="{
                  countrySelectorLabel: 'Country code',
                  countrySelectorError: 'Select a country',
                  phoneNumberLabel: `${field.label} ${field.isRequiredForAdd ? '*' : ''
                    }`,
                  example: 'Example:',
                }" />
              </div>
                <div v-if="field.type == 'Switch'">
                  <!-- <p class="h5-title">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p> -->
                  <!-- <v-switch v-model="model[field.name]"></v-switch> -->
                  <div class="editor-switch-holder grey lighten-5">
                    <div class="editor-switch-label">
                      <v-icon v-if="field.icon" size="16px" class="mr-2">{{
                        field.icon
                        }}</v-icon>
                      <span class="font-weight-bolder">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</span>
                    </div>
                    <div class="editor-switch-toggle">
                      <input v-model="model[field.name]" type="checkbox" :id="'isActorId' + i" />
                      <label :for="'isActorId' + i"> </label>
                    </div>
                  </div>
                </div>
                <div v-if="field.type == 'File'">
                  <v-file-input clear-icon="mdi-close-circle" clearable v-model="model[field.name]"
                    :label="field.label + (fieldIsRequired(field) ? ' *' : '')" outlined dense :prepend-inner-icon="field.icon ? field.icon : 'mdi-paperclip'
                      " prepend-icon=""></v-file-input>
                </div>

                <div v-if="field.type == 'Dropzone'" class="dropzone">
                  <span class="dropzone-title">Upload your file</span>
                  <p class="dropzone-paragraph">File should be an image</p>
                  <label for="file-input" class="drop-container">
                    <span class="drop-title">Drop files here</span>
                    or
                    <input type="file" accept="image/*" required="" id="file-input" />
                  </label>
                </div>

                <div v-if="field.type == 'Code'">
                  <!-- advanced -->
                  <!-- {{ model[field.name] }} -->
                  <p class="mb-1">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                  <p class="mb-1 sm-text">{{ field.hint }}</p>
                  <codemirror v-model="model[field.name]" :code="model[field.name]" class="scrollbar-style-14"
                    :options="codemirrorOption" :lintOptions="{
                      sub: true,
                      notypeof: true,
                    }">
                  </codemirror>
                </div>

                <div v-if="field.type == 'FieldX'">
                  <field-x
                  :label="field.label"
                  :hint="field.hint"
                  :multiple="field.multiple"
                  :multipleAddBtnText="field.multipleAddBtnText"
                  :emptyTexte="field.emptyTexte"
                  :fields="field.fields"
                  v-bind:model.sync="model[field.name]"
                  />
                  <!-- <v-file-input clear-icon="mdi-close-circle" clearable v-model="model[field.name]"
                    :label="field.label + (fieldIsRequired(field) ? ' *' : '')" outlined dense :prepend-inner-icon="field.icon ? field.icon : 'mdi-paperclip'
                      " prepend-icon=""></v-file-input> -->
                </div>
              </div>
            </v-col>
          </v-row>
        </div>

        <div v-if="multiple && !id" class="pt-5 mb-5">
          <div class="d-flex justify-end">
            <v-btn class="orange white--text rounded" small elevation="1" @click="addModel"><v-icon color="white"
                class="mr-2">mdi-plus</v-icon> Add row
            </v-btn>
          </div>
        </div>
        <v-row>
          <v-col cols="12">
            <v-btn :loading="loading" @click="submit()" class="white--text btn w-100 elevation-0" :class="color">
              {{ submitText }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-card>
</template>

<script>
/* eslint-disable */

// import hljs from "highlight.js";
import { methods, rules } from "../../utils/constant.js";

import { codemirror } from "vue2-codemirror-lite-js";
import Separator from "./Separator.vue";
import SelectX from "./SelectX.vue";
import FieldX from "./FieldX.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    codemirror,
    Separator,
    SelectX,
    FieldX,
    VuePhoneNumberInput
  },
  props: {
    fields: {
      type: Array,
      default: () => [
        //   {
        //       name:"",
        //       type:"String | Boolean | Array | Select | Number",
        //       col:12,
        //       isRequiredForUpdate:false,
        //       isRequiredForAdd:true,
        //       existForUpdate:true,
        //       existForAdd:true,
        //       select:{
        //           foreign:{
        //               id:"",
        //           },
        //           data:["Annee"]
        //       }
        //   }
      ],
    },
    models: {
      type: Array,
      default: () => [
        // {
        //   model: {},
        //   fields: this.fields
        // }

      ],
    },
    model: {
      default: () => {
        let result = {};
        if (this.multiple == true && !this.model && !this.model._id)
          result = [];
        return result;
      },
    },
    collection: {
      type: String,
      default: "Cette collection",
    },
    title: {
      type: String,
      default: "Editeur",
    },

    withoutImages: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },

    callback: {
      required: true,
    },
    color: {
      type: String,
      default: "primary",
    },
    submitText: {
      type: String,
      default: "Soumettre",
    },
    editorClass: {
      type: String,
      default: "elevation-5",
    },
  },
  methods: {
    
    initFields() {
      return new Promise(async (resolve, reject) => {
        try {
          for (let item of this.fields) {
            if (item.type == "Select") {
              if (item.select && item.select.store) {
                await item.select.store.action(item.select.store.params);
                // console.log("get",item.select);
                this.model[item.name] = methods.seedStoreSelect(
                  this.model[item.name],
                  item.select.store.getter
                );
                // console.log("seed", this.model[item.name]);

              } else if (
                item.select.objectData &&
                Array.isArray(item.select.objectData) &&
                item.select.objectData.length > 0
              ) {
                let unWantedData = item.select.unWantedData || [];
                console.log(unWantedData);

                if (this.model && this.model[item.name]) {
                  unWantedData = unWantedData.map((t) => {
                    if (t != this.model[item.name]) {
                      return item.name;
                    }
                  });
                }

                item.select.data = methods.getNameList(
                  item.select.objectData,
                  item.select.fieldName,
                  unWantedData
                );

                if (item.select.allowOtherValue) {
                  if (
                    this.model[item.name] &&
                    this.model[item.name].length > 0 &&
                    !item.select.data.includes(this.model[item.name])
                  ) {
                    this.model[item.name + "Other"] = this.model[item.name];
                    this.model[item.name] = "Autre";
                  }
                  item.select.data.push("Autre");
                }
              }
            }
            if (item.type == "AutoComplete") {
              if (item.select && item.select.store) {
                await item.select.store.action(item.select.store.params);
                console.log(item.select);
                this.model[item.name] = methods.seedStoreSelect(
                  this.model[item.name],
                  item.select.store.getter
                );
              } else if (
                item.select.objectData &&
                Array.isArray(item.select.objectData) &&
                item.select.objectData.length > 0
              ) {
                let unWantedData = item.select.unWantedData || [];
                console.log(unWantedData);

                if (this.model && this.model[item.name]) {
                  unWantedData = unWantedData.map((t) => {
                    if (t != this.model[item.name]) {
                      return item.name;
                    }
                  });
                }

                item.select.data = methods.getNameList(
                  item.select.objectData,
                  item.select.fieldName,
                  unWantedData
                );

                if (item.select.allowOtherValue) {
                  if (
                    this.model[item.name] &&
                    this.model[item.name].length > 0 &&
                    !item.select.data.includes(this.model[item.name])
                  ) {
                    this.model[item.name + "Other"] = this.model[item.name];
                    this.model[item.name] = "Autre";
                  }
                  item.select.data.push("Autre");
                }
              }
            }
            // if(item.type == 'Switch'){
            if (item.default) {
              this.model[item.name] = item.default;
            }
            // }

            if (["Password"].includes(item.type)) {
              this.variables[item.name] = true;
            }

            //
          }
          resolve(true);
        } catch (error) {
          reject(error);
        }
      });
    },
    isValidField(field) {
      let success = true;
      if (this.id) {
        //   update
        if (!field.existForUpdate) {
          success = false;
        }
      } else {
        //
        if (!field.existForAdd) {
          success = false;
        }
      }

      if (field.isHidden) {
        success = false;
      }

      return success;
    },
    fieldIsRequired(field) {


      if (this.id) {
        return field.isRequiredForUpdate
      } else {
        return field.isRequiredForAdd

      }
    },
    getFieldRules(field) {
      let success = true;
      let fieldRules = [];
      if (this.id) {
        //   update
        if (!field.isRequiredForUpdate) {
          success = false;
        }
      } else {
        //
        if (!field.isRequiredForAdd) {
          success = false;
        }
      }
      if (success) {
        fieldRules.push(rules.required);
      }
      if (field.rules && Array.isArray(field.rules)) {
        fieldRules = [...fieldRules, ...field.rules];
      }

      return fieldRules;
    },
    getFieldOtherRules(field) {
      let success = true;
      let fieldRules = [];
      if (this.id) {
        //   update
        if (!field.isRequiredForUpdate) {
          success = false;
        }
      } else {
        //
        if (!field.isRequiredForAdd) {
          success = false;
        }
      }
      if (success) {
        fieldRules.push(rules.required);
      }
      if (field.select.otherRules && Array.isArray(field.select.otherRules)) {
        fieldRules = [...fieldRules, ...field.select.otherRules];
      }

      return fieldRules;
    },

    getSchema(model) {
      let schema;

      if (!model) {
        model = this.model;
      }
      if (!this.withoutImages) {
        schema = new FormData();

        for (let field of this.fields) {
          if (field.isFiles) {
            for (let path of model[field.name]) {
              schema.append(path, model[field.name]);
            }
          } else {
            if (field.type == "Static") {
              schema.append(field.name, field.value);
            } else if (field.type == "Switch") {
              schema.append(field.name, model[field.name]);
            } else {
              if (["Preview", "Separator","HTMLPreview"].includes(field.type)) {
                continue;
              }
              if (!model[field.name]) continue;

              if (field.type == "Phone") {
                schema.append(field.name, model[field.name]);

                if (field.countryCallingCodeLabel) {
                  schema.append(
                    field.countryCallingCodeLabel,
                    model[field.countryCallingCodeLabel]
                  );
                } else {
                  schema.append(
                    field.name + "CountryCallingCode",
                    model[field.name + "CountryCallingCode"]
                  );
                }
                if (field.countryCodeLabel) {
                  schema.append(
                    field.countryCodeLabel,
                    model[field.countryCodeLabel]
                  );
                } else {
                  schema.append(
                    field.name + "CountryCode",
                    model[field.name + "CountryCode"]
                  );
                }
                continue;
              }

              if (field.type == "Select") {
                if (
                  field.select &&
                  field.select.store &&
                  field.select.store.getter
                ) {
                  let fieldName = field.select.store.fieldName
                    ? field.select.store.fieldName
                    : "name";
                  let fieldValue = field.select.store.fieldValue
                    ? field.select.store.fieldValue
                    : "_id";

                  schema.append(
                    field.name,
                    methods.getItemId(
                      field.select.store.getter,
                      model[field.name],
                      fieldName,
                      fieldValue
                    )
                  );
                  continue;
                }
              }
              if (field.type == "AutoComplete") {
                if (
                  field.select &&
                  field.select.store &&
                  field.select.store.getter
                ) {
                  let fieldName = field.select.store.fieldName
                    ? field.select.store.fieldName
                    : "name";
                  let fieldValue = field.select.store.fieldValue
                    ? field.select.store.fieldValue
                    : "_id";

                  schema.append(
                    field.name,
                    methods.getItemId(
                      field.select.store.getter,
                      model[field.name],
                      fieldName,
                      fieldValue
                    )
                  );
                  continue;
                }
              }

              if (field.type == "Radio") {
                if (field.radio) {
                  let fieldName = field.radio.fieldName
                    ? field.radio.fieldName
                    : "name";
                  let fieldValue = field.radio.fieldValue
                    ? field.radio.fieldValue
                    : "_id";

                  schema.append(
                    field.name,
                    methods.getItemId(
                      field.radio.data,
                      model[field.name],
                      fieldName,
                      fieldValue
                    )
                  );
                  continue;
                }
              }

              if (field.type == "Color") {
                schema.append(
                  field.name,
                  model[field.name].hexa || model[field.name]
                );
                continue;
              }

              schema.append(field.name, model[field.name]);
            }
          }
        }
      } else {
        schema = this.getSchemaWithoutFormData(model);
      }

      return schema;
    },
    getSchemaWithoutFormData(model) {
      let schema = {};
      if (!model) {
        model = this.model;
      }
      for (let field of this.fields) {
        if (field.type == "Static") {
          schema[field.name] = field.value;
        } else if (field.type == "Switch") {
          schema[field.name] = model[field.name];
        } else {
          if (["Preview", "Separator","HTMLPreview"].includes(field.type)) {
            continue;
          }
          if (!model[field.name]) continue;

          if (field.type == "Select") {
            if (
              field.select &&
              field.select.store &&
              field.select.store.getter
            ) {
              let fieldName = field.select.store.fieldName
                ? field.select.store.fieldName
                : "name";
              let fieldValue = field.select.store.fieldValue
                ? field.select.store.fieldValue
                : "_id";

              schema[field.name] = methods.getItemId(
                field.select.store.getter,
                model[field.name],
                fieldName,
                fieldValue
              );
              continue;
            }

            if (
              field.select &&
              field.select.objectData &&
              Array.isArray(field.select.objectData)
            ) {
              if (
                field.select.allowOtherValue == true &&
                model[field.name] == "Autre"
              ) {
                schema[field.name] = model[field.name + "Other"];
              } else {
                let fieldName = field.select.fieldName
                  ? field.select.fieldName
                  : "name";
                let fieldValue = field.select.fieldValue
                  ? field.select.fieldValue
                  : "value";

                schema[field.name] = methods.getItemId(
                  field.select.objectData,
                  model[field.name],
                  fieldName,
                  fieldValue
                );
              }

              continue;
            }
          }
          if (field.type == "AutoComplete") {
            if (
              field.select &&
              field.select.store &&
              field.select.store.getter
            ) {
              let fieldName = field.select.store.fieldName
                ? field.select.store.fieldName
                : "name";
              let fieldValue = field.select.store.fieldValue
                ? field.select.store.fieldValue
                : "_id";

              schema[field.name] = methods.getItemId(
                field.select.store.getter,
                model[field.name],
                fieldName,
                fieldValue
              );
              continue;
            }

            if (
              field.select &&
              field.select.objectData &&
              Array.isArray(field.select.objectData)
            ) {
              if (
                field.select.allowOtherValue == true &&
                model[field.name] == "Autre"
              ) {
                schema[field.name] = model[field.name + "Other"];
              } else {
                let fieldName = field.select.fieldName
                  ? field.select.fieldName
                  : "name";
                let fieldValue = field.select.fieldValue
                  ? field.select.fieldValue
                  : "value";

                schema[field.name] = methods.getItemId(
                  field.select.objectData,
                  model[field.name],
                  fieldName,
                  fieldValue
                );
              }

              continue;
            }
          }

          if (field.type == "Radio") {
            if (field.radio) {
              let fieldName = field.radio.fieldName
                ? field.radio.fieldName
                : "name";
              let fieldValue = field.radio.fieldValue
                ? field.radio.fieldValue
                : "_id";

              schema[field.name] = methods.getItemId(
                field.radio.data,
                model[field.name],
                fieldName,
                fieldValue
              );

              continue;
            }
          }

          if (field.type == "Color") {
            schema[field.name] = model[field.name].hexa || model[field.name];
            continue;
          }
          if (field.type == "Phone") {
            schema[field.name] = model[field.name] = model[field.name];
            if (field.countryCallingCodeLabel) {
              schema[field.countryCallingCodeLabel] =
                model[field.countryCallingCodeLabel];
            } else {
              schema[field.name + "CountryCallingCode"] =
                model[field.name + "CountryCallingCode"];
            }
            if (field.countryCodeLabel) {
              schema[field.countryCodeLabel] = model[field.countryCodeLabel];
            } else {
              schema[field.name + "CountryCode"] =
                model[field.name + "CountryCode"];
            }

            continue;
          }

          schema[field.name] = model[field.name];
        }
      }

      return schema;
    },
    async submit() {

      // validate customs too 

      let custormIsValidate = true
      if (Array.isArray(this.fields)) {
        let i = 0
        for (const field of this.fields) {
          if (["SelectX"].includes(field.type)) {
            if (this.multiple) {

            } else {

              if (Array.isArray(this.$refs[field.name + "Refs" + i]) && this.$refs[field.name + "Refs" + i].length > 0) {
                if (!this.$refs[field.name + "Refs" + i][0].validate()) {
                  if (custormIsValidate == true) {
                    custormIsValidate = false
                  }
                }
              }

            }
          }
          i++
        }
      }
      if (this.$refs.form.validate() && custormIsValidate) {
        try {
          this.loading = true;

          let models = [this.model];

          if (this.multiple == true && !this.id) {
            models = this.models.map(item => item.model);
          }
          let response
          for (let item of models) {
            let data = {};
            data.schema = this.getSchema(item);

            data.id = this.id || undefined;
            if (data.schema == undefined) {
              this.$store.dispatch("setSnackMessage", {
                message: "Something went wrong",
                snackbar: {
                  bg: "red",
                  color: "white",
                  status: true,
                },
              });
            }

            response = await this.callback(data);

            console.log("response", response);
          }

          this.$emit("success", response);
          this.$store.dispatch("setSnackMessage", {
            message:
              this.collection +
              `has been ${this.id ? "modified" : "added"} successfully`,
            snackbar: {
              color: "red",
              status: true,
            },
          });
        } catch (error) {
          console.log(error);
          this.$store.dispatch("setSnackMessage", {
            message: error.response.data.message,
            snackbar: {
              bg: "red",
              color: "white",
              status: true,
            },
          });
          // console.log(error.response);
        }

        this.loading = false;
      }
    },
    remove(item) {
      this.keywords.splice(this.keywords.indexOf(item), 1);
      this.keywords = [...this.keywords];
    },
    async addModel() {
      const concatArray = []
      for (let field of this.fields) {
        concatArray.push(Object.assign({}, field))
      }
      const models = this.models.concat([{
        model: {},
        fields: Object.assign([], [...concatArray])
      }]);
      await this.$emit("update:models", models);

    },
    removeModel(index) {
      this.models.splice(index, 1);
    },

    async executeButtonAction(action) {
      this.loading = true;
      if (action.type == "return-model") {
        // this.model = Object.assign(this.model, action.function(this.model));
        this.model = await action.function(this.model);
      }
      this.loading = false;
    },
    wathVirtualModelHandler() {

      for (let item of this.virtualFields) {
        if (Array.isArray(item.conditions) && item.conditions) {
          for (let condition of item.conditions) {
            if (this.model[condition.field] == condition.value) {
              // if (condition.action == 'hidden') {
              //   fieldsToRemove.push(item.name)
              //   console.log(this.model[condition.field],condition.value ,fieldsToRemove)
              // }
              // else if (condition.action == 'show') {
              //   fieldsToAdd.push(item.name)
              // }

              // else{
              // }
            } else {
              this.$emit("initFieldsValue")
            }
          }
        }
      }
    },
    watchModelHandler(thisModelData = undefined, newModels = undefined, modelIndex = undefined) {
      return new Promise(async (resolve, reject) => {
        try {
          let thisModel, thisFields

          if (thisModelData) {
            thisFields = thisModelData.fields
            thisModel = thisModelData.model
          }


          if (!thisModel) {
            thisModel = this.model
          }
          if (!thisFields) {
            thisFields = this.fields
          }

          this.id = thisModel._id || undefined;

          let fieldsToAdd = [];
          let fieldsToRemove = [];


          for (let item of thisFields) {


            if (Array.isArray(item.conditions) && item.conditions) {
              for (let condition of item.conditions) {
                if ((thisModel[condition.field] == condition.value) ||
                  (Array.isArray(condition.values) && condition.values.includes(thisModel[condition.field]))
                ) {
                  if (condition.action == 'default') {
                    if (!thisModel[item.name] && condition.content) {
                      thisModel[item.name] = condition.content
                    }
                  }
                  if (condition.action == 'hidden') {
                    item.isHidden = true
                  }

                  if (condition.action == 'show') {
                    // fieldsToAdd.push(item.name)
                    item.isHidden = false

                  }

                  if (condition.action == 'set-select-data' && ['Select', "SelectX"].includes(item.type)) {
                    item.select.data = condition.data.yes
                  }


                } else {
                  if (condition.action == 'hidden') {
                    item.isHidden = false
                  } else if (condition.action == 'show') {
                    item.isHidden = true
                  } else if (condition.action == 'set-select-data' && ['Select', "SelectX"].includes(item.type)) {
                    item.select.data = condition.data.no
                  }
                }
              }
            }
            if (
              item.conditionFields &&
              Array.isArray(item.conditionFields) &&
              item.conditionFields.length > 0
            ) {

              if (thisModel[item.name]) {
                for (let condition of item.conditionFields) {
                  // alert(condition.value )
                  // alert(thisModel[item.name] )
                  if (condition.value == thisModel[item.name]) {
                    // we need to add conditions fields to bigest options
                    for (let conditionField of condition.fields) {
                      let fieldExist = false;
                      for (let subfield of thisFields) {
                        if (subfield.name == conditionField.name) {
                          fieldExist = true;
                          break;
                        }
                      }
                      if (!fieldExist) {
                        fieldsToAdd.push(conditionField);
                      }
                    }
                  } else {
                    // we need to remove conditions fields
                    fieldsToRemove.push(condition.value);
                  }
                }
              }
            }
          }

          let newFields = thisFields.concat(fieldsToAdd);
          newFields = newFields.filter(function (field, index, arr) {
            // console.log(index, arr);
            return !fieldsToRemove.includes(field.category);
          });

          // newFields = newFields.filter(function (field, index, arr) {
          //   // console.log(index, arr);
          //   return !fieldsToRemove.includes(field.name);
          // });


          this.loading = true;
          // console.log(newFields)

          if (newModels) {
            // console.log("newFields",newFields)
            newModels[modelIndex].fields = newFields
            await this.$emit("update:models", newModels);

          } else {
            await this.$emit("update:fields", newFields);

          }
          fieldsToAdd = [];
          fieldsToRemove = [];

          this.loading = false;
          resolve(true);
        } catch (error) {
          reject(error);
        } finally {
          this.loading = false;
        }
      });
    },

    copyToClipboard(data) {
      if (!navigator.clipboard) {
        console.error('Clipboard API not supported');
        return;
      }
      navigator.clipboard.writeText(data).then(() => {
        console.log('Data copied to clipboard successfully!');
        this.copiedText = "Copied"
        setTimeout(() => {
          this.copiedText = "Copy"
        }, 2000)
      }).catch(err => {
        console.error('Failed to copy data to clipboard: ', err);
      });
    }
  },
  watch: {
    model: {
      async handler(newVal, oldVal) {
        await this.watchModelHandler();
        // = this.fields.concat(fieldsToAdd)
        // alert('j',this.id)
      },
      deep: true,
    },
    models: {
      async handler(newVal, oldVal) {

        if (JSON.stringify(newVal) === JSON.stringify(oldVal)) {
          return
        }

        if (oldVal) {
          oldVal.forEach((item, index) => {
            this.$off(`modelChange-${index}`);
          });
        }




        const assignModels = this.models

        // Loop through each item in the array
        newVal.forEach((item, index) => {
          // Watch changes to the 'model' key of each item
          this.$watch(() => item.model, async (newModel, oldModel) => {

            // console.log('Model changed for item at index', index, ':', newModel);
            // await this.watchModelHandler(item, Object.assign([], assignModels), index);
            await this.watchModelHandler(Object.assign({}, item), Object.assign([], assignModels), index);
          }, { deep: true, name: `modelChange-${index}` });
        });


      },
      deep: true,
      immediate: true
    },
    fields() {
      for (let item of this.fields) {
        if (item.type == "Select") {
          if (item.select && item.select.store) {
            if (
              this.model[item.name] &&
              this.model[item.name][item.select.store.fieldName]
            ) {
              this.model[item.name] =
                this.model[item.name][item.select.store.fieldName];
            }
            console.log(item.select.store);
            item.select.data = methods.getNameList(
              item.select.store.getter || [],
              item.select.store.fieldName
            );
          }
        }
        if (item.type == "AutoComplete") {
          if (item.select && item.select.store) {
            if (
              this.model[item.name] &&
              this.model[item.name][item.select.store.fieldName]
            ) {
              this.model[item.name] =
                this.model[item.name][item.select.store.fieldName];
            }
            console.log(item.select.store);
            item.select.data = methods.getNameList(
              item.select.store.getter || [],
              item.select.store.fieldName
            );
          }
        }
      }
    },
  },
  async mounted() {
    // this.keywords = this.model.keywords || [];
    // setTimeout(
    // async()=>{
    this.id = this.model._id || undefined;

    // alert(this.id)
    // }
    // ,2000)

    await this.initFields();
    await this.watchModelHandler();
    // this.fieldList = this.fields.map(item=>item.name)
    /**
     * category list
     */
    // this.categoriesList = this.categories ? methods.getNameList(this.categories) : [];
    /**
     * category old
     */
    // this.categoriesModel = this.model.categories
    //   ? methods.getNameList(this.model.categories)
    //   : [];
  },

  data() {
    return {
      id: "",
      rules,
      defaultKeySuggestion: [],
      categoryModel: "",
      categoriesList: [],
      categoriesModel: [],
      fieldList: [],
      keywords: [],
      images: [],
      dialog: false,
      loading: false,
      timeMenu: false,
      dateMenu: false,
      DEFAULT_COUNTRY_CODE: undefined,
      dateMenus: [],
      variables: {},
      codemirrorOption: {
        strict: false,
        tabSize: 2,
        lineNumbers: true,
        lineWrapping: false,
        line: true,
        // gutters: ['CodeMirror-linenumbers', 'CodeMirror-lint-markers'],
        lint: true,
      },

      virtualFields: [],
      copiedText: "Copy"
    };
  },
};
</script>

<style>
.multiple-container .CodeMirror {
  border: 1px solid #eee;
  height: 10vh;
}

.multiple-container {
  width: 100%;
  padding: 0;
  margin: 0 0 2rem 0;
  /* background-color: rgba(243, 240, 240, 0.428); */
}

.single-container .CodeMirror {
  border: 1px solid #eee;
  height: 40vh !important;
}

.sm-text {
  font-size: 0.7rem;
  color: rgb(34, 34, 34);
}

.editor-form {
  max-height: 80vh;
  overflow-y: scroll;
}

.h-codemirror {
  height: 12rem;
  border: 1px solid #eee;
}

.h-multiple-codemirror {
  height: 2rem;
  border: 1px solid #eee;
}

pre {
  background-color: #f4f1f170;
  border-radius: 5px;
  font-size: 0.8rem;
  color: #2e383c;
  line-height: 1.5;
  overflow-x: scroll;
}

.w-100 {
  width: 100%;
}

.dropzone {
  background-color: #fff;
  box-shadow: 0 10px 60px rgb(218, 229, 255);
  border: 1px solid rgb(159, 159, 160);
  border-radius: 20px;
  padding: 2rem 0.7rem 0.7rem 0.7rem;
  text-align: center;
  font-size: 1.125rem;
  /*max-width: 320px;*/
  max-width: 100%;
}

.dropzone-title {
  color: #000000;
  font-size: 1.8rem;
  font-weight: 500;
}

.dropzone-paragraph {
  margin-top: 10px;
  font-size: 0.9375rem;
  color: rgb(105, 105, 105);
}

.drop-container {
  background-color: #fff;
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 2.1875rem;
  border-radius: 10px;
  border: 2px dashed rgb(171, 202, 255);
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: rgba(0, 140, 255, 0.164);
  border-color: rgba(17, 17, 17, 0.616);
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

#file-input {
  width: 350px;
  max-width: 100%;
  color: #444;
  padding: 2px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid rgba(8, 8, 8, 0.288);
}

#file-input::file-selector-button {
  margin-right: 20px;
  border: none;
  background: #084cdf;
  padding: 10px 20px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

#file-input::file-selector-button:hover {
  background: #0d45a5;
}

/*  */

/* switch */
.editor-switch-holder {
  display: flex;
  padding: 10px 20px;
  border-radius: 10px;
  /* box-shadow: -8px -8px 15px rgba(255, 255, 255, .7),
        10px 10px 10px rgba(0, 0, 0, .2),
        inset 8px 8px 15px rgba(255, 255, 255, .7),
        inset 10px 10px 10px rgba(0, 0, 0, .2); */
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
}

.editor-switch-label {
  padding: 0 20px 0 10px;
  box-sizing: border-box;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
}

.editor-switch-label i {
  margin-right: 5px;
}

.editor-switch-toggle {
  height: 40px;
}

.editor-switch-toggle input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -2;
}

.editor-switch-toggle input[type="checkbox"]+label {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset -8px -8px 15px rgba(255, 255, 255, 0.6),
    inset 10px 10px 10px rgba(0, 0, 0, 0.25);
}

.editor-switch-toggle input[type="checkbox"]+label::before {
  position: absolute;
  content: "NO";
  font-size: 13px;
  text-align: center;
  line-height: 25px;
  top: 8px;
  left: 8px;
  width: 45px;
  height: 25px;
  border-radius: 20px;
  background-color: #eeeeee;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.5),
    3px 3px 5px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-in-out;
}

.editor-switch-toggle input[type="checkbox"]:checked+label::before {
  left: 50%;
  content: "YES";
  color: #fff;
  background-color: #00b33c;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.5), 3px 3px 5px #00b33c;
}

/*
 *  STYLE 14
 */

.scrollbar-style-14::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6);
  background-color: #cccccc04;
}

.scrollbar-style-14::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.scrollbar-style-14::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent);
}

/*
 *  STYLE 14
 */

.CodeMirror-vscrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6) !important;
  background-color: #cccccc04 !important;
}

.CodeMirror-vscrollbar::-webkit-scrollbar {
  width: 5px !important;
  background-color: #f5f5f5 !important;
}

.CodeMirror-vscrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff !important;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent) !important;
}

.CodeMirror-hscrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6) !important;
  background-color: #cccccc04 !important;
}

.CodeMirror-hscrollbar::-webkit-scrollbar {
  width: 5px !important;
  height: 5px;

  background-color: #f5f5f5 !important;
}

.CodeMirror-hscrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff !important;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent) !important;
}

/*
 *  STYLE 14
 */

.v-dialog::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.6);
  background-color: #cccccc04 !important;
}

.v-dialog::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5 !important;
}

.v-dialog::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(90deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 1) 25%,
      transparent 100%,
      rgba(0, 0, 0, 1) 75%,
      transparent) !important;
}

.alert{
  background-color: rgba(0, 217, 255, 0.239) ;
}
</style>
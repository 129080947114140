<template>

    <div>
        <div>
            <h3 class="mb-2">{{ label }}</h3>
            <p class="mb-2" v-if="hint"> {{ hint }}</p>
        </div>
        <div>
            <!-- {{ privateModel }} -->
            <v-row class="py-0 multiple-container " v-if="multiple">

                <v-col v-if="privateModel.length == 0" cols="12">
                    <div class="d-flex justify-center py-2 align-items-center">
                        <div class="text-center">
                            <v-icon>mdi-thought-bubble</v-icon>
                            <p class="mt-3">{{ emptyTexte }}</p>
                        </div>
                    </div>
                </v-col>
                <v-col v-else v-for="(iModel, e) of privateModel" :key="e" cols="12" class="">
                    <v-row class="pb-0 mb-0">

                        <v-col v-for="(field, i) of iModel.fields" :key="i"
                            :cols="multiple ? field.multipleCol : field.col"
                            :md="multiple ? field.multipleMdCol : field.md"
                            :v-show="field.type == 'Static' || !isValidField(field) ? false : true">

                            <div v-if="isValidField(field)">
                                <div v-if="field.type == 'Separator'">
                                    <separator :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                        :size="field.size" :weight="field.weight" :width="field.width"
                                        :align="field.align" :type="field.textType" />
                                </div>
                                <div v-if="field.type == 'Preview'">
                                    <div class="mb-2 d-flex justify-space-between">
                                        <h3 class=" h5-title ">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}
                                            :</h3>
                                        <button class="elevation-0 btn px-2"
                                            @click.prevent="copyToClipboard(iModel.model[field.name])"><v-icon
                                                size=".9rem">{{
                                                    copiedText == 'Copy' ?
                                                        'mdi-content-copy' : 'mdi-check' }}</v-icon> {{ copiedText
                                            }}</button>
                                    </div>
                                    <pre class="scrollbar-style-14">{{ iModel.model[field.name] }}</pre>
                                    <v-divider></v-divider>
                                </div>
                                <v-text-field clear-icon="mdi-close-circle" clearable v-if="field.type == 'String'"
                                    v-model="iModel.model[field.name]" :rules="getFieldRules(field)" color="main"
                                    :hint="field.hint" :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                    :placeholder="field.placeholder"
                                    :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'" outlined
                                    dense></v-text-field>
                                <v-text-field v-if="field.type == 'Number'" type="number"
                                    v-model="iModel.model[field.name]" :rules="getFieldRules(field)" color="main"
                                    :hint="field.hint" :placeholder="field.placeholder" dense
                                    :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :prepend-inner-icon="field.icon ? field.icon : 'mdi-numeric'
                                        " outlined></v-text-field>

                                <div v-if="field.type == 'Select'">
                                    <v-select clear-icon="mdi-close-circle" clearable :items="field.select.data"
                                        :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                        v-model="iModel.model[field.name]" :rules="getFieldRules(field)" outlined
                                        :hint="field.hint" :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'
                                            " small-chips dense :multiple="field.select.multiple"></v-select>

                                    <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.select.allowOtherValue == true &&
                                        iModel.model[field.name] == 'Autre'
                                    " v-model="iModel.model[field.name + 'Other']" :rules="getFieldOtherRules(field)"
                                        color="main" :hint="field.hint" :label="'Spécifier votre suggestion'"
                                        :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'
                                            " outlined />
                                </div>

                                <div v-if="field.type == 'Color'">
                                    <v-color-picker v-model="iModel.model[field.name]" class="ma-2"
                                        hide-canvas></v-color-picker>
                                </div>
                                <div v-if="field.type == 'Date'">
                                    <v-menu :ref="field.name" v-model="dateMenus[field.name]"
                                        :close-on-content-click="false" :return-value.sync="iModel.model[field.name]"
                                        transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-combobox v-model="iModel.model[field.name]"
                                                :multiple="field.range ? field.range : false" chips small-chips
                                                :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                                prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                                outlined></v-combobox>
                                        </template>
                                        <v-date-picker v-model="iModel.model[field.name]"
                                            :multiple="field.range ? field.range : false" no-title scrollable>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary"
                                                @click="dateMenus[field.name] = false">Annuler</v-btn>
                                            <v-btn text color="primary" @click="
                                                $refs[field.name][0].save(iModel.model[field.name])
                                                ">
                                                Confirmer
                                            </v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </div>

                                <div>
                                    <v-menu ref="timeMenu" v-if="field.type == 'Time'" v-model="timeMenu"
                                        :close-on-content-click="false" :nudge-right="40"
                                        :return-value.sync="iModel.model[field.name]" transition="scale-transition"
                                        offset-y max-width="290px" min-width="290px">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field clear-icon="mdi-close-circle" clearable dense
                                                v-model="iModel.model[field.name]"
                                                :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                                prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                                v-on="on"></v-text-field>
                                        </template>
                                        <v-time-picker v-if="timeMenu" v-model="iModel.model[field.name]" full-width
                                            @click:minute="
                                                $refs.timeMenu[0].save(iModel.model[field.name])
                                                "></v-time-picker>
                                    </v-menu>
                                </div>
                                <div v-if="field.type == 'Radio'">
                                    <p>{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                                    <v-radio-group row v-model="iModel.model[field.name]">
                                        <v-radio v-for="(item, i) of field.radio.data" :key="i" color="primary"
                                            :label="item.name" :value="item.value"></v-radio>
                                    </v-radio-group>
                                </div>
                                <div v-if="field.type == 'Switch'">
                                    <!-- <p class="h4-description">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
    <v-switch v-model="iModel.model[field.name]"></v-switch> -->
                                    <div class="editor-switch-holder grey lighten-5">
                                        <div class="editor-switch-label">
                                            <v-icon v-if="field.icon" size="16px" class="mr-2">{{
                                                field.icon
                                                }}</v-icon>
                                            <span class="font-weight-bolder">{{
                                                field.label
                                                }}</span>
                                        </div>
                                        <div class="editor-switch-toggle">
                                            <input v-model="iModel.model[field.name]" type="checkbox"
                                                :id="'isActorId' + e + `-${field.name}`" />
                                            <label :for="'isActorId' + e + `-${field.name}`"></label>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="field.type == 'File'">
                                    <v-file-input clear-icon="mdi-close-circle" clearable
                                        v-model="iModel.model[field.name]"
                                        :label="field.label + (fieldIsRequired(field) ? ' *' : '')"></v-file-input>
                                </div>

                                <div v-if="field.type == 'Code'">
                                    <!-- advanced -->
                                    <!-- {{ iModel.model[field.name] }} -->
                                    <p class="mb-1">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                                    <p class="mb-1 sm-text">{{ field.hint }}</p>
                                    <codemirror v-model="iModel.model[field.name]" :code="iModel.model[field.name]"
                                        :options="codemirrorOption" class="scrollbar-style-14 h-multiple-codemirror"
                                        :lintOptions="{
                                            sub: true,
                                            notypeof: true,
                                        }">
                                    </codemirror>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="1" class="d-flex justify-center align-center">
                            <v-btn class="red--text" icon @click="removeModel(e)"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <div v-if="privateModel.length - 1 > (e)">
                        <v-divider class="grey lighten-4"></v-divider>
                        <v-divider class="grey lighten-4"></v-divider>
                        <v-divider class="grey lighten-4"></v-divider>
                        <v-divider class="grey lighten-4"></v-divider>
                        <v-divider class="grey lighten-4"></v-divider>
                    </div>
                </v-col>
            </v-row>
            <v-row v-else>
                <v-col v-for="(field, i) of fields" :key="i" :cols="field.col" :md="field.md ? field.md : field.col"
                    :v-show="(field.type == 'Static' || !isValidField(field)) ? false : true">
                    <div v-if="isValidField(field)">
                        <div v-if="field.type == 'Separator'">
                            <separator :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :size="field.size"
                                :weight="field.weight" :width="field.width" :align="field.align"
                                :type="field.textType" />
                        </div>
                        <div v-if="field.type == 'Preview'">

                            <div class="mb-2 d-flex  justify-space-between">
                                <h3 class="mb-1">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }} :</h3>

                                <button class="elevation-0 btn px-2"
                                    @click.prevent="copyToClipboard(privateModel[field.name])"><v-icon size=".9rem">{{
                                        copiedText
                                            == 'Copy'
                                            ?
                                            'mdi-content-copy' : 'mdi-check' }}</v-icon> {{ copiedText
                                    }}</button>
                            </div>
                            <pre>{{ privateModel[field.name] }}</pre>

                            <v-divider></v-divider>
                        </div>
                        <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.type == 'String'"
                            v-model="privateModel[field.name]" :rules="getFieldRules(field)" :hint="field.hint"
                            :placeholder="field.placeholder" color="main"
                            :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                            :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'" outlined></v-text-field>

                        <v-text-field clear-icon="mdi-close-circle" clearable v-if="field.type == 'Password'"
                            @click:append="variables[field.name] = !variables[field.name]"
                            v-model="privateModel[field.name]"
                            :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :hint="field.hint"
                            :prepend-inner-icon="field.icon ? field.icon : 'mdi-key'" :append-icon="variables[field.name] ? 'mdi-eye' : 'mdi-eye-off'
                                " :rules="getFieldRules(field)" :type="variables[field.name] ? 'password' : 'text'"
                            counter outlined dense></v-text-field>
                        <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.type == 'Number'"
                            type="number" :hint="field.hint" :placeholder="field.placeholder"
                            v-model="privateModel[field.name]" :rules="getFieldRules(field)" color="main"
                            :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                            :prepend-inner-icon="field.icon ? field.icon : 'mdi-numeric'" outlined></v-text-field>

                        <v-btn dense v-if="field.type == 'Button'" @click="executeButtonAction(field.action)"
                            :color="field.color" class="w-100" :loading="loading">
                            {{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}
                        </v-btn>

                        <div v-if="field.type == 'Select'">
                            <v-select clear-icon="mdi-close-circle" clearable :items="field.select.data"
                                :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                v-model="privateModel[field.name]" :hint="field.hint" :rules="getFieldRules(field)"
                                @change="field.onChange ? field.onChange.function : ''" outlined
                                :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'" chips dense
                                :multiple="field.select.multiple"></v-select>

                            <v-text-field clear-icon="mdi-close-circle" clearable dense v-if="field.select.allowOtherValue == true &&
                                privateModel[field.name] == 'Autre'
                            " v-model="privateModel[field.name + 'Other']" :rules="getFieldOtherRules(field)"
                                color="main" :label="'Spécifier votre suggestion'"
                                :prepend-inner-icon="field.icon ? field.icon : 'mdi-text'" outlined />
                        </div>
                        <div v-if="field.type == 'SelectX'">
                            <!-- -->

                            <select-x :items="field.select.data"
                                :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                v-model="privateModel[field.name]" v-bind:model.sync="privateModel[field.name]"
                                :hint="field.hint" :rules="getFieldRules(field)"
                                @change="field.onChange ? field.onChange.function : ''" outlined
                                :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'" chips dense
                                :userPlan="field.userPlan" :multiple="field.select.multiple"
                                :ref="field.name + 'Refs' + i" />
                        </div>
                        <div v-if="field.type == 'AutoComplete'">
                            <v-autocomplete clear-icon="mdi-close-circle" clearable
                                :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                v-model="privateModel[field.name]" :hint="field.hint" :items="field.select.data"
                                :rules="getFieldRules(field)" @change="field.onChange ? field.onChange.function : ''"
                                outlined :prepend-inner-icon="field.icon ? field.icon : 'mdi-select'" chips dense
                                :multiple="field.select.multiple">
                            </v-autocomplete>
                        </div>

                        <div v-if="field.type == 'Color'">
                            <v-color-picker v-model="privateModel[field.name]" class="ma-2"
                                hide-canvas></v-color-picker>
                        </div>
                        <div v-if="field.type == 'Date'">
                            <v-menu :ref="field.name" v-model="dateMenus[field.name]" :close-on-content-click="false"
                                :return-value.sync="privateModel[field.name]" transition="scale-transition" offset-y
                                min-width="auto">

                                <template v-slot:activator="{ on, attrs }">
                                    <v-combobox v-model="privateModel[field.name]"
                                        :multiple="field.range ? field.range : false" chips small-chips
                                        :label="field.label + (fieldIsRequired(field) ? ' *' : '')"
                                        prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" :hint="field.hint"
                                        v-on="on" outlined></v-combobox>
                                </template>
                                <v-date-picker v-model="privateModel[field.name]"
                                    :multiple="field.range ? field.range : false" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="dateMenus[field.name] = false">Annuler</v-btn>
                                    <v-btn text color="primary"
                                        @click="$refs[field.name][0].save(privateModel[field.name])">
                                        Confirmer
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </div>

                        <div>
                            <v-menu ref="timeMenu" v-if="field.type == 'Time'" v-model="timeMenu"
                                :close-on-content-click="false" :nudge-right="40"
                                :return-value.sync="privateModel[field.name]" transition="scale-transition" offset-y
                                max-width="290px" min-width="290px">

                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field clear-icon="mdi-close-circle" clearable dense
                                        v-model="privateModel[field.name]"
                                        :label="field.label + (fieldIsRequired(field) ? ' *' : '')" :hint="field.hint"
                                        prepend-inner-icon="mdi-clock-time-four-outline" readonly v-bind="attrs"
                                        v-on="on"></v-text-field>
                                </template>
                                <v-time-picker v-if="timeMenu" v-model="privateModel[field.name]" full-width
                                    @click:minute="$refs.timeMenu[0].save(privateModel[field.name])"></v-time-picker>
                            </v-menu>
                        </div>
                        <div v-if="field.type == 'Radio'">
                            <p>{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                            <v-radio-group row v-model="privateModel[field.name]">
                                <v-radio v-for="(item, i) of field.radio.data" :key="i" color="primary"
                                    :label="item.name" :value="item.value"></v-radio>
                            </v-radio-group>
                        </div>
                        <div v-if="field.type == 'Switch'">
                            <!-- <p class="h5-title">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p> -->
                            <!-- <v-switch v-model="privateModel[field.name]"></v-switch> -->
                            <div class="editor-switch-holder grey lighten-5">
                                <div class="editor-switch-label">
                                    <v-icon v-if="field.icon" size="16px" class="mr-2">{{
                                        field.icon
                                        }}</v-icon>
                                    <span class="font-weight-bolder">{{ field.label }} {{ fieldIsRequired(field) ? '*' :
                                        ''
                                        }}</span>
                                </div>
                                <div class="editor-switch-toggle">
                                    <input v-model="privateModel[field.name]" type="checkbox" :id="'isActorId' + i" />
                                    <label :for="'isActorId' + i"> </label>
                                </div>
                            </div>
                        </div>
                        <div v-if="field.type == 'File'">
                            <v-file-input clear-icon="mdi-close-circle" clearable v-model="privateModel[field.name]"
                                :label="field.label + (fieldIsRequired(field) ? ' *' : '')" outlined dense
                                :prepend-inner-icon="field.icon ? field.icon : 'mdi-paperclip'
                                    " prepend-icon=""></v-file-input>
                        </div>

                        <div v-if="field.type == 'Dropzone'" class="dropzone">
                            <span class="dropzone-title">Upload your file</span>
                            <p class="dropzone-paragraph">File should be an image</p>
                            <label for="file-input" class="drop-container">
                                <span class="drop-title">Drop files here</span>
                                or
                                <input type="file" accept="image/*" required="" id="file-input" />
                            </label>
                        </div>

                        <div v-if="field.type == 'Code'">
                            <!-- advanced -->
                            <!-- {{ privateModel[field.name] }} -->
                            <p class="mb-1">{{ field.label }} {{ fieldIsRequired(field) ? '*' : '' }}</p>
                            <p class="mb-1 sm-text">{{ field.hint }}</p>
                            <codemirror v-model="privateModel[field.name]" :code="privateModel[field.name]"
                                class="scrollbar-style-14" :options="codemirrorOption" :lintOptions="{
                                    sub: true,
                                    notypeof: true,
                                }">
                            </codemirror>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div v-if="multiple" class="pt-5 mb-5">
            <div class="d-flex justify-end">
                <v-btn class="orange white--text rounded" small elevation="1" @click="addModel"><v-icon color="white"
                        class="mr-2">mdi-plus</v-icon> {{ multipleAddBtnText }}
                </v-btn>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */

import { rules, methods } from "../../utils/constant.js";

import { codemirror } from "vue2-codemirror-lite-js";
import Separator from "./Separator.vue";
import SelectX from "./SelectX.vue";

export default {
    components: {
        codemirror,
        Separator,
        SelectX,
    },
    props: {

        multiple: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        hint: {
            type: String,
            default: ''
        },
        fields: {
            type: Array,
            default: () => []
        },
        model: null,

        emptyTexte: {
            type: String,
            default: () => ("When you add a row, he will come here.")
        },
        multipleAddBtnText: {
            type: String,
            default: () => ("Add row")
        },

    },
    data() {
        return {
            timeMenu: false,
            dateMenu: false,
            dateMenus: [],
            codemirrorOption: {
                strict: false,
                tabSize: 2,
                lineNumbers: true,
                lineWrapping: false,
                line: true,
                // gutters: ['CodeMirror-linenumbers', 'CodeMirror-lint-markers'],
                lint: true,
            },

            copiedText: "Copy",
            privateModel: {},
            privateValueIsProvideOnce: false,
            variables: {},
            models: []


        }
    },
    methods: {
        isValidField(field) {
            let success = true;
            if (this.id) {
                //   update
                if (!field.existForUpdate) {
                    success = false;
                }
            } else {
                //
                if (!field.existForAdd) {
                    success = false;
                }
            }

            if (field.isHidden) {
                success = false;
            }

            return success;
        },
        fieldIsRequired(field) {


            if (this.id) {
                return field.isRequiredForUpdate
            } else {
                return field.isRequiredForAdd

            }
        },
        getFieldRules(field) {
            let success = true;
            let fieldRules = [];
            if (this.id) {
                //   update
                if (!field.isRequiredForUpdate) {
                    success = false;
                }
            } else {
                //
                if (!field.isRequiredForAdd) {
                    success = false;
                }
            }
            if (success) {
                fieldRules.push(rules.required);
            }
            if (field.rules && Array.isArray(field.rules)) {
                fieldRules = [...fieldRules, ...field.rules];
            }

            return fieldRules;
        },
        getFieldOtherRules(field) {
            let success = true;
            let fieldRules = [];
            if (this.id) {
                //   update
                if (!field.isRequiredForUpdate) {
                    success = false;
                }
            } else {
                //
                if (!field.isRequiredForAdd) {
                    success = false;
                }
            }
            if (success) {
                fieldRules.push(rules.required);
            }
            if (field.select.otherRules && Array.isArray(field.select.otherRules)) {
                fieldRules = [...fieldRules, ...field.select.otherRules];
            }

            return fieldRules;
        },
        copyToClipboard(data) {
            if (!navigator.clipboard) {
                console.error('Clipboard API not supported');
                return;
            }
            navigator.clipboard.writeText(data).then(() => {
                console.log('Data copied to clipboard successfully!');
                this.copiedText = "Copied"
                setTimeout(() => {
                    this.copiedText = "Copy"
                }, 2000)
            }).catch(err => {
                console.error('Failed to copy data to clipboard: ', err);
            });
        },
        initFields() {
            return new Promise(async (resolve, reject) => {
                try {
                    for (let item of this.fields) {
                        if (item.type == "Select") {
                            if (item.select && item.select.store) {
                                await item.select.store.action(item.select.store.params);
                                // console.log("get",item.select);
                                this.privateModel[item.name] = methods.seedStoreSelect(
                                    this.privateModel[item.name],
                                    item.select.store.getter
                                );

                            } else if (
                                item.select.objectData &&
                                Array.isArray(item.select.objectData) &&
                                item.select.objectData.length > 0
                            ) {
                                let unWantedData = item.select.unWantedData || [];
                                console.log(unWantedData);

                                if (this.privateModel && this.privateModel[item.name]) {
                                    unWantedData = unWantedData.map((t) => {
                                        if (t != this.privateModel[item.name]) {
                                            return item.name;
                                        }
                                    });
                                }

                                item.select.data = methods.getNameList(
                                    item.select.objectData,
                                    item.select.fieldName,
                                    unWantedData
                                );

                                if (item.select.allowOtherValue) {
                                    if (
                                        this.privateModel[item.name] &&
                                        this.privateModel[item.name].length > 0 &&
                                        !item.select.data.includes(this.privateModel[item.name])
                                    ) {
                                        this.privateModel[item.name + "Other"] = this.privateModel[item.name];
                                        this.privateModel[item.name] = "Autre";
                                    }
                                    item.select.data.push("Autre");
                                }
                            }
                        }
                        if (item.type == "AutoComplete") {
                            if (item.select && item.select.store) {
                                await item.select.store.action(item.select.store.params);
                                console.log(item.select);
                                this.privateModel[item.name] = methods.seedStoreSelect(
                                    this.privateModel[item.name],
                                    item.select.store.getter
                                );
                            } else if (
                                item.select.objectData &&
                                Array.isArray(item.select.objectData) &&
                                item.select.objectData.length > 0
                            ) {
                                let unWantedData = item.select.unWantedData || [];
                                console.log(unWantedData);

                                if (this.privateModel && this.privateModel[item.name]) {
                                    unWantedData = unWantedData.map((t) => {
                                        if (t != this.privateModel[item.name]) {
                                            return item.name;
                                        }
                                    });
                                }

                                item.select.data = methods.getNameList(
                                    item.select.objectData,
                                    item.select.fieldName,
                                    unWantedData
                                );

                                if (item.select.allowOtherValue) {
                                    if (
                                        this.privateModel[item.name] &&
                                        this.privateModel[item.name].length > 0 &&
                                        !item.select.data.includes(this.privateModel[item.name])
                                    ) {
                                        this.privateModel[item.name + "Other"] = this.privateModel[item.name];
                                        this.privateModel[item.name] = "Autre";
                                    }
                                    item.select.data.push("Autre");
                                }
                            }
                        }
                        // if(item.type == 'Switch'){
                        if (item.default) {
                            this.privateModel[item.name] = item.default;
                        }
                        // }

                        if (["Password"].includes(item.type)) {
                            this.variables[item.name] = true;
                        }

                        //
                    }
                    resolve(true);
                } catch (error) {
                    reject(error);
                }
            });
        },
        watchInitFields() {
            return new Promise(async (resolve, reject) => {
                try {
                    for (let item of this.fields) {
                        // console.log("item",item)
                        if (item.type == "Select") {
                            if (item.select && item.select.store) {

                                if (
                                    this.privateModel[item.name] &&
                                    this.privateModel[item.name][item.select.store.fieldName]
                                ) {
                                    this.privateModel[item.name] =
                                        this.privateModel[item.name][item.select.store.fieldName];
                                }


                                item.select.data = methods.getNameList(
                                    item.select.store.getter || [],
                                    item.select.store.fieldName
                                );

                                console.log("here store", item.select.data);
                            }
                        }
                        if (item.type == "AutoComplete") {
                            if (item.select && item.select.store) {
                                if (
                                    this.privateModel[item.name] &&
                                    this.privateModel[item.name][item.select.store.fieldName]
                                ) {
                                    this.privateModel[item.name] =
                                        this.privateModel[item.name][item.select.store.fieldName];
                                }
                                // console.log(item.select.store);
                                item.select.data = methods.getNameList(
                                    item.select.store.getter || [],
                                    item.select.store.fieldName
                                );
                            }
                        }
                    }
                    resolve(true)
                } catch (error) {
                    reject(true)

                }
            })
        },
        emitModel(models) {
            return new Promise(async (resolve, reject) => {
                try {
                    if (models) {
                        let output 

                        if (this.multiple == true) {
                            output= []
                            if(Array.isArray(models)){
                                output = models.map(item => this.formatOutputForModel(item.model, item.fields));
                            }
                        } else {
                            output={}
                            output = this.formatOutputForModel(models, this.fields)
                        }
                        await this.$emit("update:model", output);
                    }

                    resolve(true)
                } catch (error) {
                    reject(error)
                }
            })

        },
        async addModel() {
            const concatArray = []
            for (let field of this.fields) {
                concatArray.push(Object.assign({}, field))
            }


            const models = this.privateModel.concat([{
                model: {},
                fields: Object.assign([], [...concatArray])
            }]);
            this.privateModel = models
            // await this.$emit("update:model", models);
            await this.emitModel(models)

        },
        async removeModel(index) {
            this.privateModel.splice(index, 1);
            await this.emitModel(this.privateModel)

        },
        formatOutputForModel(model, fields) {
            let schema = {};

            for (let field of fields) {
                if (field.type == "Static") {
                    schema[field.name] = field.value;
                } else if (field.type == "Switch") {
                    schema[field.name] = model[field.name];
                } else {
                    if (["Preview", "Separator"].includes(field.type)) {
                        continue;
                    }
                    if (!model[field.name]) continue;

                    if (field.type == "Select") {
                        if (
                            field.select &&
                            field.select.store &&
                            field.select.store.getter
                        ) {
                            let fieldName = field.select.store.fieldName
                                ? field.select.store.fieldName
                                : "name";
                            let fieldValue = field.select.store.fieldValue
                                ? field.select.store.fieldValue
                                : "_id";

                            schema[field.name] = methods.getItemId(
                                field.select.store.getter,
                                model[field.name],
                                fieldName,
                                fieldValue
                            );
                            continue;
                        }

                        if (
                            field.select &&
                            field.select.objectData &&
                            Array.isArray(field.select.objectData)
                        ) {
                            if (
                                field.select.allowOtherValue == true &&
                                model[field.name] == "Autre"
                            ) {
                                schema[field.name] = model[field.name + "Other"];
                            } else {
                                let fieldName = field.select.fieldName
                                    ? field.select.fieldName
                                    : "name";
                                let fieldValue = field.select.fieldValue
                                    ? field.select.fieldValue
                                    : "value";

                                schema[field.name] = methods.getItemId(
                                    field.select.objectData,
                                    model[field.name],
                                    fieldName,
                                    fieldValue
                                );
                            }

                            continue;
                        }
                    }
                    if (field.type == "AutoComplete") {
                        if (
                            field.select &&
                            field.select.store &&
                            field.select.store.getter
                        ) {
                            let fieldName = field.select.store.fieldName
                                ? field.select.store.fieldName
                                : "name";
                            let fieldValue = field.select.store.fieldValue
                                ? field.select.store.fieldValue
                                : "_id";

                            schema[field.name] = methods.getItemId(
                                field.select.store.getter,
                                model[field.name],
                                fieldName,
                                fieldValue
                            );
                            continue;
                        }

                        if (
                            field.select &&
                            field.select.objectData &&
                            Array.isArray(field.select.objectData)
                        ) {
                            if (
                                field.select.allowOtherValue == true &&
                                model[field.name] == "Autre"
                            ) {
                                schema[field.name] = model[field.name + "Other"];
                            } else {
                                let fieldName = field.select.fieldName
                                    ? field.select.fieldName
                                    : "name";
                                let fieldValue = field.select.fieldValue
                                    ? field.select.fieldValue
                                    : "value";

                                schema[field.name] = methods.getItemId(
                                    field.select.objectData,
                                    model[field.name],
                                    fieldName,
                                    fieldValue
                                );
                            }

                            continue;
                        }
                    }

                    if (field.type == "Radio") {
                        if (field.radio) {
                            let fieldName = field.radio.fieldName
                                ? field.radio.fieldName
                                : "name";
                            let fieldValue = field.radio.fieldValue
                                ? field.radio.fieldValue
                                : "_id";

                            schema[field.name] = methods.getItemId(
                                field.radio.data,
                                model[field.name],
                                fieldName,
                                fieldValue
                            );

                            continue;
                        }
                    }

                    if (field.type == "Color") {
                        schema[field.name] = model[field.name].hexa || model[field.name];
                        continue;
                    }

                    schema[field.name] = model[field.name];
                }
            }

            return schema;
        },
    },
    watch: {
        privateModel: {

            async handler(newVal, oldVal) {
                if (this.privateValueIsProvideOnce) {
                    // this.validate()
                }
                if (this.privateModel) {
                    this.privateValueIsProvideOnce = true

                    await this.emitModel(this.privateModel)
                }
            },
            deep: true,
            immediate: true
        },
        fields() {
            this.watchInitFields()
        },
    },
    async mounted() {

        if (this.multiple == true && !this.model) {
            this.privateModel = []

        } else {
            this.privateModel = {}
        }
        await this.initFields()
        await this.watchInitFields()



    }
}
</script>